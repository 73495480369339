.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* coming soon - addition */
.particles-section{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.85;
}
.particles-section .particle{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
/* .particles-section particle{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
} */
.particles-section canvas{
  display: inline-block;
}
.comming-soon-section{
  background-color: #FFF;
  /* background-color: rgb(17, 35, 53); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(17, 35, 53);
  /* color: #fff; */
}
.comming-soon-section .container{
  max-width: 1200px;
  margin: 0px auto;
  width: 85%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding: 50px 0px;
}
.comming-soon-section .container h1{
  font-family: "Poppins", sans-serif;
  /* font-family: "Roboto", sans-serif; */
}
.comming-soon-section .container h2{
  font-size: 36px;
  font-family: "Frank Ruhl Libre", serif;
  line-height: 1.35;
  text-align: center;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.comming-soon-section .container p{
  font-size: 16px;
  font-family: "Frank Ruhl Libre", serif;
  /* color: rgba(17, 35, 53, 0.6); */
  color: #000000;
  /* color: #fff; */
  line-height: 1.941;
  text-align: center;
  font-weight: 400;
  max-width: 420px;
}
.comming-soon-section .footer{
  padding: 10px 0px;
}
.comming-soon-section .footer p{
  font-size: 14px;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif;
}
